export default {
  favicon: true,
  appleTouchIcon: false,
  logo: true,
  title: 'PA Learning Platform',
  site: 'pa',
  GA_CODE: 'UA-120537958-1',
  locale: 'enDefault',
  rememberMeDefault: true,
  features: {
    SPONSOR_AUTH: false,
  },
};
