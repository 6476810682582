// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import VueResource from 'vue-resource';
import Vuelidate from 'vuelidate';
import VueAnalytics from 'vue-analytics';
import Raven from 'raven-js';
import RavenVue from 'raven-js/plugins/vue';
// import GetTextPlugin from 'vue-gettext';
import settings from 'settings';
import App from './App';
import router from './router';
// import { errorInterceptor } from './services/error';

Vue.config.productionTip = false;

Vue.use(VueResource);

if (settings.GA_CODE) {
  Vue.use(VueAnalytics, {
    id: settings.GA_CODE,
    router,
  });
}

Vue.use(Vuelidate);

if (process.env.NODE_ENV !== 'development') {
  Raven.config('https://6f34bcc0a77d4d9e8152ab6f7a5a3e41@sentry2.tuimedia.com/77').addPlugin(RavenVue, Vue).install();

  // Add a Vue error handler that adds the user profile to Sentry
  // Chain the previous error handler, if there is one
  const oldOnError = Vue.config.errorHandler;
  Vue.config.errorHandler = function VueErrorHandler(error, vm) {
    let user = {};

    if (vm.User) {
      user = vm.User.getters.profile;
    }

    Raven.setUserContext(user);

    if (typeof oldOnError === 'function') {
      oldOnError.call(this, error, vm);
    }
  };
}
// /* eslint-disable */
// const translations = require(`./i18n/output/${
//   process.env.VUE_APP_SITE
// }/translations.json`);
// /* eslint-enable */

// Vue.use(GetTextPlugin, {
//   availableLanguages: {
//     'en-GB': 'English UK',
//     'pl-PL': 'Polish',
//     'fr-FR': 'French France',
//     'fr-CA': 'French Canada',
//   },
//   defaultLanguage: 'pl-PL',
//   translations,
//   silent: !(process.env.NODE_ENV === 'development'), // Log errors in development
// });

// Handle errors
// Vue.http.interceptors.push(errorInterceptor);

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  template: '<App/>',
  components: { App },
});
