<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import settings from 'settings';
// import { getPageData } from './services/api';

export default {
  name: 'app',
  data() {
    return {
      page: {
        id: '',
        title: '',
        section: '',
        type: '',
        status: '',
      },
      sections: [],
      pendingRequests: 0,
      siteName: '',
    };
  },
  created() {
    document.title = settings.title;
    // Add site favicon
    if (settings.favicon) {
      const link = document.createElement('link');
      const random = Math.floor(Math.random() * 20);
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = `${process.env.BASE_URL}/images/${settings.site}_favicon.png?${random}`;
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    if (settings.appleTouchIcon) {
      const iPhoneLink = document.createElement('link');
      iPhoneLink.rel = 'apple-touch-icon';
      iPhoneLink.href = `/auth/images/${settings.site}_apple-touch-icon.png`;
      document.getElementsByTagName('head')[0].appendChild(iPhoneLink);

      const iPadLink = document.createElement('link');
      iPadLink.rel = 'apple-touch-icon';
      iPadLink.sizes = '76x76';
      iPadLink.href = `/auth/images/${settings.site}_apple-touch-icon-ipad.png`;
      document.getElementsByTagName('head')[0].appendChild(iPadLink);

      const iPhoneRetinaLink = document.createElement('link');
      iPhoneRetinaLink.rel = 'apple-touch-icon';
      iPhoneRetinaLink.sizes = '120x120';
      iPhoneRetinaLink.href = `/auth/images/${settings.site}_apple-touch-icon-iphone-retina.png`;
      document.getElementsByTagName('head')[0].appendChild(iPhoneRetinaLink);

      const iPadRetinaLink = document.createElement('link');
      iPadRetinaLink.rel = 'apple-touch-icon';
      iPadRetinaLink.sizes = '152x152';
      iPadRetinaLink.href = `/auth/images/${settings.site}_apple-touch-icon-ipad-retina.png`;
      document.getElementsByTagName('head')[0].appendChild(iPadRetinaLink);
    }
    // this.loadData();
  },
  // watch: {
  //   $route() {
  //     // this.loadData();
  //   },
  // },
  // methods: {
  //   loadData() {
  //     this.page.id = 'login';
  //     this.page.type = 'resources';
  //     this.page.status = 'published';
  //     this.page.status = '';

  //     const requests = [
  //       () => getPageData(this.page.type, this.page.id, this.page.status).then((data) => {
  //         this.page.title = data.data.metadata.title;
  //         this.sections = data.data.content.data;
  //         if (data.data.elements) {
  //           this.page.section = data.data.elements.data[0].title || '';
  //         }
  //         this.pendingRequests -= 1;
  //       }),
  //     ];
  //     this.pendingRequests = requests.length;
  //     requests.forEach(rq => rq());
  //   },
  // },
};
</script>

<style lang="scss">
@import './css/main.scss';
@import '~theme';
</style>
