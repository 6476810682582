import Vue from 'vue';
import Router from 'vue-router';

const LoginView = () => import('@/views/login');
const SSOLogoutView = () => import('@/views/sso-logout');
const AccessDeniedView = () => import('@/views/access-denied');
const RegisterView = () => import('@/views/register');
const PasswordResetView = () => import('@/views/password-reset');
const PasswordChangeView = () => import('@/views/password-change');
const SponsorPendingView = () => import('@/views/sponsor-pending');
const SponsorRejectedView = () => import('@/views/sponsor-rejected');
const SponsorChangeView = () => import('@/views/sponsor-change');
const SponsorResponseView = () => import('@/views/sponsor-response');
const LinkExpiredView = () => import('@/views/link-expired');

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: { name: 'login' },
    },
    {
      path: '/login/:locale?',
      name: 'login',
      component: LoginView,
    },
    {
      path: '/sso-logout',
      name: 'sso-logout',
      component: SSOLogoutView,
    },
    {
      path: '/access-denied',
      name: 'access-denied',
      component: AccessDeniedView,
    },
    {
      path: '/register/:locale?',
      name: 'register',
      component: RegisterView,
    },
    {
      path: '/password-reset/:locale?',
      name: 'password-reset',
      component: PasswordResetView,
    },
    {
      path: '/password-change/:locale?',
      name: 'password-change',
      component: PasswordChangeView,
    },
    {
      path: '/pending',
      name: 'sponsor-pending',
      component: SponsorPendingView,
    },
    {
      path: '/rejected',
      alias: ['/denied'],
      name: 'sponsor-rejected',
      component: SponsorRejectedView,
    },
    {
      path: '/sponsor-change',
      name: 'sponsor-change',
      component: SponsorChangeView,
    },
    {
      path: '/sponsor-response',
      name: 'sponsor-response',
      component: SponsorResponseView,
    },
    {
      path: '/link-expired',
      name: 'link-expired',
      component: LinkExpiredView,
    },
  ],
});
